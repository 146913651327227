import React, { useEffect, useRef } from "react";
import { Layout } from "../../../layout";
import MainImage from "../../../images/contactForm/contact-form-image.svg";
import Support from "../../../images/support/support-tiemViwer.svg";
import Phone from "../../../images/contactForm/phone.svg";
import PhoneGreen from "../../../images/contactForm/phoneGreen.svg";
import Email from "../../../images/contactForm/email.svg";
import EmailGreen from "../../../images/contactForm/emailGreen.svg";
import { styled } from "../../../../stitches.config";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import TeamViewer from "../../../images/teamviewer.png";
import { useScreenResolution } from "../../../hooks/useScreenResolution";
import {
  isBrowser,
  openTeamViewer,
} from "../../../components/shared/LiveSupport";
import { routes } from "../../../routes/routes";
import { useAppSelector } from "../../../hooks/ReduxHooks";
import { Seo } from "../../../components/shared/Seo";

export const RemoteMaintenance = () => {
  const { resolution } = useScreenResolution();
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const { primaryNumber, supportEmail } = useAppSelector(
    (state) => state.contactInfo
  );

  // const ref = useRef<HTMLDivElement | null>(null);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   if (ref && ref.current) {
  //     script.src = "https://ifbck.com/api/widget/c4f85b772ea67a81/widget.js";
  //     script.async = true;
  //     script.defer = true;
  //     script.type = "application/javascript";
  //     ref.current?.appendChild(script);
  //   }
  //   return () => {
  //     ref.current?.removeChild(script);
  //   };
  // }, []);

  return (
    <Layout>
      <Seo
        title={t("fernwartung.title")}
        description={t("fernwartung.text")}
        image={MainImage}
      />
      <PageContainer>
        <Container>
          <Box>
            <PageTitle>{t("fernwartung.title")}</PageTitle>

            <div>
              <ButtonsWrap>
                <Text>{t("fernwartung.text")}</Text>
                <ButtonEmail href={`mailto:${supportEmail}`}>
                  {supportEmail}
                </ButtonEmail>
              </ButtonsWrap>
              <ButtonsWrap>
                <Text>{t("fernwartung.text2")}</Text>
                <ButtonTel href={`tel:${primaryNumber}`}>
                  {primaryNumber}
                </ButtonTel>
              </ButtonsWrap>
            </div>
          </Box>
          <SupportContainer>
            <SupportImage src={Support} />
            <SupportBox>
              <SupportTitle>{t("fernwartung.teamViewer")}</SupportTitle>
              {resolution == "mobile" ? (
                <SupportButton
                  onClick={() => navigate(routes.contact.contactForm)}
                >
                  {t("eDataGatewayContactUs")}
                </SupportButton>
              ) : (
                <SupportButton onClick={() => isBrowser() && openTeamViewer()}>
                  <img src={TeamViewer} />
                  {t("liveSupport")}
                </SupportButton>
              )}
            </SupportBox>
          </SupportContainer>
        </Container>
        {/* <FormTitle>{t("connectUsingForm")}</FormTitle>
        <StyledContainer
          ref={(interalRef) => (ref.current = interalRef)}
          className="elementor-widget-container "
        ></StyledContainer> */}
      </PageContainer>
    </Layout>
  );
};

const Container = styled("div", {
  margin: "32px auto",
  maxWidth: "1280px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "0 16px",
  "@md": {
    padding: "24px 0px",
    gap: 64,
    alignItems: "stretch",
    justifyContent: "space-between",
    flexDirection: "row",
  },
});

const SupportContainer = styled("div", {
  background: "$supportTeamViewerBg",
  borderRadius: "4px",
  padding: "60px 16px",
  margin: "32px 0 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  "@md": {
    margin: "0",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    padding: "44px 70px",
  },
});

const SupportBox = styled("div", {
  width: "100%",

  "@md": {
    maxWidth: 240,
  },
});

const SupportButton = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 14,
  width: "100%",
  cursor: "pointer",
  padding: "14px",
  border: "none",
  borderRadius: 4,
  background: "$accent",
  color: "$white",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "20px",
  transition: "all 500ms ease",
  "@md": {
    fontWeight: 400,
    padding: "8px 12px",
  },
  "&:hover": {
    backgroundColor: "$liveSupportHover",
  },
});

const SupportTitle = styled("h1", {
  margin: "0 0 32px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  color: "$white",
  "@md": {
    textAlign: "start",
    margin: "0 0 20px",
  },
});

const SupportImage = styled("img", {
  width: 172,
  marginBottom: 50,
  "@md": {
    width: 152,
    margin: "0 10px 0 0",
  },
});

const Box = styled("div", {
  width: "100%",

  "@md": {
    maxWidth: "694px",
  },
});

const ButtonTel = styled("a", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 14,
  width: "100%",
  cursor: "pointer",
  textDecoration: "none",
  padding: "14px",
  border: "none",
  borderRadius: 8,
  background: "$primaryBackground",
  color: "$text",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "20px",
  transition: "all 500ms ease",
  "@md": {
    padding: "22px 50px",
    maxWidth: "320px",
    justifyContent: "flex-start",
  },
  "&:hover": {
    backgroundColor: "$accent",
    color: "$white",
    "&:before": {
      transition: "all 0.5s ease",
      background: `url(${Phone}) no-repeat`,
    },
  },
  "&:before": {
    content: "",
    position: "absolute",
    top: "37%",
    left: 20,
    width: 22,
    height: 22,
    background: `url(${PhoneGreen}) no-repeat`,
  },
});

const ButtonEmail = styled("a", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 14,
  width: "100%",
  cursor: "pointer",
  textDecoration: "none",
  padding: "14px",
  border: "none",
  borderRadius: 8,
  background: "$primaryBackground",
  color: "$text",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "20px",
  transition: "all 500ms ease",
  "@md": {
    padding: "22px 50px",
    justifyContent: "flex-start",
    maxWidth: "320px",
  },
  "&:hover": {
    backgroundColor: "$accent",
    color: "$white",
    "&:before": {
      transition: "all 0.5s ease",
      background: `url(${Email}) no-repeat`,
    },
  },
  "&:before": {
    content: "",
    position: "absolute",
    top: "37%",
    left: 20,
    width: 22,
    height: 22,
    background: `url(${EmailGreen}) no-repeat`,
  },
});

const PageTitle = styled("h1", {
  margin: "0 0 12px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "40px",
  color: "$heading",
  "@md": {
    color: "$text",
    textAlign: "start",
  },
});

const Text = styled("p", {
  margin: "0 0 12px",
  fontWeight: 400,
  fontSize: "17px",
  lineHeight: "24px",
  color: "$text",
  whiteSpace: "pre-line",
  "@md": {
    margin: "16px 0 12px",
    fontSize: "15px",
    lineHeight: "20px",
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginTop: 24,
  gap: 14,
  "@md": {
    gap: 8,
    marginTop: 16,
  },
});

const StyledContainer = styled("div", {
  width: "100%",
  padding: "0px 16px",
  margin: "0 auto",
  "@md": {
    maxWidth: 1280,
    padding: "32px 0px",
  },
  "#iFrameWrapper-c4f85b772ea67a81": {
    width: "100%",
    maxWidth: "unset",
    maxHeight: "unset",
    boxShadow: "none !important",
    height: "1000px",
    margin: 0,
    iframe: {
      border: "none",
    },
  },

  ".elementor-widget-container": {
    width: "100%",
  },
});

const FormTitle = styled("h1", {
  margin: "0 0 12px",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "$heading",
  padding: "0 16px",
  "@md": {
    color: "$text",
    textAlign: "start",
  },
});

const PageContainer = styled("div", {
  "@md": {
    maxWidth: 1280,
    margin: "0 auto",
  },
});
